
import * as cheet from 'cheet.js'
import { EventBus } from './event_bus'
import globals from './globals'
import api from './api'


var lv = {}

let control_main = {
    connect_first(){
        lv.task = 'first_contact'
        lv.event = 'data_first_contact'
        api.call({
            task: lv.task,
            event: lv.event,
            global: false
        })
        EventBus.$on(lv.event, (e) => {
            control_main.connect_first_done(e)
        })
    },
    connect_first_done(d){
        if(d.result == 'error'){
            control_main.console_log('sorry, no connection')
            return
        }
        if(d.result == 'ok'){
            control_main.console_log('have connection')
        }
        globals.connection_established = true
        EventBus.$emit('connect_first_success')
    },
    preloader_done(){
        EventBus.$emit('preloader_done')
    },
    console_log(msg){
        lv.golog = false
        if(process.env.NODE_ENV !== 'production'){
            lv.golog = true
        }
        if(globals.consolify){
            lv.golog = true
        }
        if(lv.golog){
            console.log(msg)
        }
    }
}

cheet('g l o b a l s', function () {
    console.log(globals)
})
 
cheet('c o n s o l i f y', function () {
    if(localStorage.getItem('consolify')){
        console.log('got consolify, removing it')
        globals.consolify = false
        localStorage.removeItem('consolify')
    }else{
        console.log('havent got consolify, setting it')
        globals.consolify = true
        localStorage.setItem('consolify', true)
    }
})
  
cheet('l i v e u p d a t e', function () {
    EventBus.$emit('live_update')
    if(globals.live_update){
        globals.live_update = false
        console.log('DISBLED LIVE UPDATE')
    }else{
        globals.live_update = true
        console.log('ENABLED LIVE UPDATE')
    }
})
  
export default control_main
