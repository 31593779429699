
import { EventBus } from './event_bus'
import { globals } from './globals'
import control_main from '@/modules/control_main'
import * as cheet from 'cheet.js'
import axios from 'axios'
import sha256 from 'crypto-js/sha256'

var lv = {}
lv.keysource = 'd8heyrgftyr6775ytugh5y4h8u5j994e5e4d5f4t55njf984yg4h55y4hu5j4i8632k1i878uoooplw2841'
lv.url_base = globals.host + 'api/'
lv.url_api = lv.url_base + '_api.php'

let api = {

   makeKey(str) {
      lv.keysplit = lv.keysource.split('55')
      lv.sha = sha256(lv.keysplit[1] + str).toString()
   },
   callData(props) {
      lv.data = props
      lv.data_string = JSON.stringify(lv.data)
      lv.key = api.makeKey(lv.data_string)

      axios.post(lv.url_api, {
         task: props.task,
         data: JSON.stringify(lv.data),
         key: lv.sha
      }, { withCredentials: true })
      .then(function (response) {
         control_main.console_log('---- api ---- ' + props.task)
         control_main.console_log(response.data)

         if (response.data.result == 'ok') {
            lv.obj = props.task
            lv.adata = response.data.data.data
            if (props.global) {
               if(props.task == 'kit_item_fetch'){
                  lv.obj = 'kit_item_' + props.item
                  lv.adata = response.data.data.data.item
               }
               api.setGlobal({ obj: lv.obj, data: lv.adata })
            }

            if(props.task == 'first_contact'){
               lv.obj = 'footer_items'
               lv.adata = response.data.data.data.items
            }
            api.setGlobal({ obj: lv.obj, data: lv.adata })
           
         }

         EventBus.$emit(props.event, response.data)
      })
      .catch(function (error) {
         lv.error = {
            result: 'error',
            message: 'Something went wrong... try again later. Sorry!',
            error: error
         }
         console.log(error)
         EventBus.$emit(props.event, lv.error)
      })
   },
   call(props) {

      if(globals.live_update){
         api.callData(props)
         return
      }

      if (globals.api_data[props.task] && props.global != false) {
         control_main.console_log('---- fetch from globals ---- ' + props.task )
         lv.res = {
            result: 'ok',
            data: {
               respond: 'allready fetched'
            }
         }
         EventBus.$emit(props.event, lv.res)
         return
      }

      if(props.task == 'kit_item_fetch'){
         if(globals.api_data['kit_item_' + props.item]){
            control_main.console_log('---- fetch kit item from globals ---- ' + props.item )
            lv.res = {
               result: 'ok',
               data: {
                  respond: 'allready fetched'
               }
            }
            EventBus.$emit(props.event, lv.res)
            return
         }
      }

      //control_main.console_log('---- new api result ----')
      api.callData(props)
   },
   setGlobal(d) {
      globals.api_data[d.obj] = d.data
   },
}


export default api
