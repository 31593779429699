import Vue from 'vue'
import app from './app.vue'
import router from './router'


if (process.env.NODE_ENV === 'production') {
  console.log('%c Ysselsteyn ', 'background: #000000; color: #fff; padding:3px; font-size:16px;')
}

import "@/assets/styles/main.css"
import "@/assets/styles/tailwind.css"
import globals from './modules/globals'

Vue.config.productionTip = false

/* ---------------------------------------------------- */
// directives
Vue.directive('click-outside',
{
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
/* ---------------------------------------------------- */


function app_start(){
  new Vue({
    router,
    render: h => h(app)
  }).$mount('#app')
}

/*
function persons_all(){
  fetch('json/data/persons_all.json?v=' + globals.content_version)
  .then(response => response.json())
  .then(function(data){
    //console.log('persons')
    //console.log(data)
    globals.fields_data = data.fields
    globals.persons_data = data.items
    info_data()
  })
}
function info_data(){
  fetch('json/data/info_data.json?v=' + globals.content_version)
  .then(response => response.json())
  .then(function(data){
    console.log('info_data')
    console.log(data)
    globals.microtranslations = data.microtranslations
    globals.hotspots = data.hotspots
    globals.article_chapters = data.articles
    app_start()
  })
}
persons_all()
*/



app_start()


