
export var globals = {
    //host: 'http://localhost/',
    host: 'http://archief-ysselsteyn.nl/',
    assets_path: null,
    content_version: 2,
    consolify: false,
    connection_established: false,
    api_data:{},
    fps_show: false,
    menudev_show: false,
    persons_filtered: null,
    language: 'nl',
    idle_time_sec: 180,
    carrousel_time: 10
}

globals.assets_path = globals.host

if(process.env.NODE_ENV === 'production'){
    globals.assets_path = '/'
}

export default globals