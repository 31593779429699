<template>
  <div id="app" class="pos-g">

    <router-view />

    <!--  
    <div class="pos-g pointer-events-none opacity-25">
      <img src="@/assets/img/temp/layout_explore.png" />
    </div>
    <div class="pos-g pointer-events-none opacity-25">
      <img src="@/assets/img/temp/layout_map.png" />
    </div>
    -->

    <div id="curtain_main" class="pos-g bg-kettle"></div>

    <div id="loading_string" class="pos-center text-white text-sm text-center">
      Ysselsteyn<br/>
      Datavisualisaties
    </div>

  </div>
</template>


<script>
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'
import control_main from '@/modules/control_main'

import gsap from 'gsap'


export default {
  name: "app",
  components: {
  },
  data() {
    return {
    }
  },
  mounted () {
    EventBus.$on('curtain_open', (e) => {
        this.curtain_open()
    })
  },
  methods: {
    curtain_open(){
      gsap.to('#curtain_main',{duration:1, opacity:0, display: 'none', ease:'power3.inOut'})
    }
  }
}



</script>

<style>

</style>
